import React from "react";

function Twitter() {
  return (
    <div className="twitter">
      <a target="_blank">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="svg-background"
        >
          <rect
            x="0.5"
            y="0.5"
            width="31"
            height="31"
            rx="15.5"
            stroke="#48a145"
          />
          <g clip-path="url(#clip0_432_1640)">
            <path
              d="M21.7198 13.2151C21.729 13.3386 21.729 13.4622 21.729 13.5857C21.729 17.3533 18.7594 21.6945 13.332 21.6945C11.6599 21.6945 10.1066 21.2268 8.79999 20.4151C9.03756 20.4416 9.26596 20.4504 9.51268 20.4504C10.8924 20.4504 12.1624 20.0004 13.1766 19.2328C11.8792 19.2063 10.7919 18.3857 10.4172 17.2563C10.6 17.2827 10.7827 17.3004 10.9746 17.3004C11.2396 17.3004 11.5046 17.2651 11.7513 17.2033C10.399 16.9386 9.38474 15.7916 9.38474 14.4063V14.371C9.77762 14.5828 10.2345 14.7151 10.7187 14.7327C9.92381 14.221 9.40302 13.3474 9.40302 12.3592C9.40302 11.8298 9.54918 11.3445 9.80504 10.921C11.2578 12.6504 13.4416 13.7798 15.8903 13.9033C15.8447 13.6916 15.8172 13.471 15.8172 13.2504C15.8172 11.6798 17.133 10.4004 18.7685 10.4004C19.6183 10.4004 20.3858 10.7445 20.9249 11.3004C21.5919 11.1769 22.2314 10.9386 22.798 10.6122C22.5786 11.2739 22.1127 11.8298 21.5005 12.1827C22.0944 12.121 22.6701 11.9621 23.2 11.7416C22.798 12.3063 22.2955 12.8092 21.7198 13.2151Z"
              fill="#48a145"
              className="socialmedia-icon"
            />
          </g>
          <defs>
            <clipPath id="clip0_432_1640">
              <rect
                width="14.4"
                height="12"
                fill="white"
                transform="translate(8.79999 10.4004)"
              />
            </clipPath>
          </defs>
        </svg>
      </a>
    </div>
  );
}

export default Twitter;
